import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4a42c35c = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _03b9196c = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _24dedd6d = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _4c450b11 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _6ba247b1 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _5b9129a5 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _414e67a3 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _699703be = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _73aed272 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _31ff7a30 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _5d0ca1bc = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _48bffc54 = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _326bb4da = () => interopDefault(import('../pages/sale/index.vue' /* webpackChunkName: "pages/sale/index" */))
const _0b1f6431 = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _2bf7342e = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _3330cad8 = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _41da8c7b = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _6d8c89f1 = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _8c66176c = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _89df78ac = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _4a109b9b = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _0eeaf3e1 = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _08bd07d5 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _a84dd510 = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _51c7603e = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _6980bcb2 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _b595c2ae = () => interopDefault(import('../pages/sale/output.vue' /* webpackChunkName: "pages/sale/output" */))
const _4e9f8c79 = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _31e90297 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _208e2331 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _8cb1cd30 = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _696f48b6 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _d91f7df8 = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _a249a888 = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _416f6904 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _31d961a6 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _8c0f4c7e = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _6e531bc6 = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _f9e31bfe = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _2cfbefcf = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _55a5bd4f = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _989836ca = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _2c0a54c4 = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _114edae9 = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _2ddf3fac = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _2a345a38 = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _2d6ce43c = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _1a5c3bfc = () => interopDefault(import('../pages/setting/import/Detail.vue' /* webpackChunkName: "pages/setting/import/Detail" */))
const _68f29a04 = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _58691320 = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _5549b6f7 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _29086582 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _fdf17d80 = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _880fb59c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0eee6f36 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _27f719ca = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _744c6919 = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _6916b9a6 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _7059337f = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _9f23a52c = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _3643434e = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _57685921 = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _3162b46c = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _0d0e25b8 = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _38b84f2e = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _e877e58c = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _136f5715 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _67e9c50d = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _6dd08259 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _daa8c222 = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _518fe324 = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _71a3534a = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _21732fc4 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _3ab4e20c = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _1f129c28 = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _91edf888 = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _4a42c35c,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _03b9196c,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _24dedd6d,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _4c450b11,
    name: "calendar"
  }, {
    path: "/customer",
    component: _6ba247b1,
    name: "customer"
  }, {
    path: "/details",
    component: _5b9129a5,
    name: "details"
  }, {
    path: "/goals",
    component: _414e67a3,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _699703be,
    name: "maisoku"
  }, {
    path: "/media",
    component: _73aed272,
    name: "media"
  }, {
    path: "/privacy",
    component: _31ff7a30,
    name: "privacy"
  }, {
    path: "/project",
    component: _5d0ca1bc,
    name: "project"
  }, {
    path: "/response",
    component: _48bffc54,
    name: "response"
  }, {
    path: "/sale",
    component: _326bb4da,
    name: "sale"
  }, {
    path: "/achievement/output",
    component: _0b1f6431,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _2bf7342e,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _3330cad8,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _41da8c7b,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _6d8c89f1,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _8c66176c,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _89df78ac,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _4a109b9b,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _0eeaf3e1,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _08bd07d5,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _a84dd510,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _51c7603e,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _6980bcb2,
    name: "response-registration"
  }, {
    path: "/sale/output",
    component: _b595c2ae,
    name: "sale-output"
  }, {
    path: "/setting/announcement",
    component: _4e9f8c79,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _31e90297,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _208e2331,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _8cb1cd30,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _696f48b6,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _d91f7df8,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _a249a888,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _416f6904,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _31d961a6,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _8c0f4c7e,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _6e531bc6,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _f9e31bfe,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _2cfbefcf,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _55a5bd4f,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _989836ca,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _2c0a54c4,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _114edae9,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _2ddf3fac,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _2a345a38,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _2d6ce43c,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/Detail",
    component: _1a5c3bfc,
    name: "setting-import-Detail"
  }, {
    path: "/setting/import/registration",
    component: _68f29a04,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _58691320,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _5549b6f7,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _29086582,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _fdf17d80,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _880fb59c,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _0eee6f36,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _27f719ca,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _744c6919,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _6916b9a6,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _7059337f,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _9f23a52c,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _3643434e,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _57685921,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _3162b46c,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _0d0e25b8,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _38b84f2e,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _e877e58c,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _136f5715,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _67e9c50d,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _6dd08259,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _daa8c222,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _518fe324,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _71a3534a,
    name: "property-property",
    children: [{
      path: "contact",
      component: _21732fc4,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _3ab4e20c,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _1f129c28,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _91edf888,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
